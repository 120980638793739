import React,{useEffect, useState} from 'react'
import { /*Link, Script, ScriptStrategy,*/ graphql } from "gatsby"

//import axios from 'axios';
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import useWindowSize from './../../hooks/useGatsbyWindowSize'
//import { useMediaQuery } from 'react-responsive'

import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
import "animate.css/animate.min.css";
//import ScrollAnimation from 'react-animate-on-scroll';
//import SvgEl from './../../components/SvgEl'
//import LOGO from './../../svg/logos'
import EventsList from './../../components/EventsList';




gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const Events_G_Page = ({ data }) => {

//  console.log(data.photos.edges);


//  const [isLoaded, setIsLoaded] = useState(false);
//  const [isMobile, setIsMobile] = useState(false);


//  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
//  const {width,height} = useWindowSize();
//    var device = '';










  useEffect(() => {
  //  setIsMobile(mobile);
  //  console.log('loaded')

  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">
    <div id="page-wrapper" className="mt-5">


    <div className="container pt-4 p-2 text-center">

    <h1 className="mt-5 titling">HOUSE EVENTS</h1>
    <p className="text-center p-2"> <a className="text-center p-2 font-lm" href="/events/private-parties/">PRIVATE PARTIES</a> | <a className="text-center p-2 font-lm" href="/events/weddings/">WEDDINGS</a></p>

    </div>


      <EventsList url={'https://discover.lilleymansion.com/api/v1/culinary-events'}/>



    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

Events_G_Page.Layout = Layout

export default Events_G_Page

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "home"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
